import "./Tech.css";
import js from "../../../images/js.png";
import s from "../../../images/s.png";
import e from "../../../images/e.png";
import react from "../../../images/react.png";
import c from "../../../images/c.png";

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import AOS from 'aos';
import 'aos/dist/aos.css'
AOS.init();

function Tech() {
  return (
    <div className='conatiner-fluid'>
      <div className='container   '>
        <div className='tech-hd '>
          <h1>Technology Stack</h1>


        </div>
        <div className='tech-desc'>
          <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
            Velit officia consequat duis enim velit mollit. Exercitation venia</p>
        </div>
        
         
         
          <div className='swipp '>
            <Swiper
              slidesPerView={5}
              spaceBetween={30}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              className="mySwiper " 
            style={{ paddingLeft: '68px',paddingBottom: '44px', paddingTop:'25px' }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                480: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 15,
                }, 1024: {
                  slidesPerView: 4,
                  spaceBetween: 15,
                }, 1280: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },

              }} >

              <SwiperSlide>

                <div className="tech-dev-card">
                  <img src={e} className="img-fluid life-img" alt="slider" />
                 
                </div>
              </SwiperSlide>
              <SwiperSlide>

                <div className="tech-dev-card ">
                  <img src={js} className="img-fluid life-img" alt="slider" />
                 
                </div>
              </SwiperSlide>
              <SwiperSlide>

                <div className="tech-dev-card">
                  <img src={react} className="img-fluid life-img" alt="slider" />
                
                </div>
              </SwiperSlide>
              <SwiperSlide>

                <div className="tech-dev-card ">
                  <img src={c} className="img-fluid life-img" alt="slider" />
                 
                </div>
              </SwiperSlide>
              
             
              <SwiperSlide>

                <div className="tech-dev-card">
                  <img src={s} className="img-fluid life-img" alt="slider" />
                 
                </div>
              </SwiperSlide>

              <SwiperSlide>

                <div className="tech-dev-card ">
                  <img src={e} className="img-fluid life-img" alt="slider" />

                </div>
              </SwiperSlide>
              <SwiperSlide>

                <div className="tech-dev-card ">
                  <img src={js} className="img-fluid life-img" alt="slider" />

                </div>
              </SwiperSlide>
              <SwiperSlide>

                <div className="tech-dev-card ">
                  <img src={react} className="img-fluid life-img" alt="slider" />

                </div>
              </SwiperSlide>
              <SwiperSlide>

                <div className="tech-dev-card ">
                  <img src={c} className="img-fluid life-img" alt="slider" />

                </div>
              </SwiperSlide>


              <SwiperSlide>

                <div className="tech-dev-card">
                  <img src={s} className="img-fluid life-img" alt="slider" />

                </div>
              </SwiperSlide>




            </Swiper>
          </div>
        

   
      </div>


    </div>
  )
}

export default Tech
import React from 'react'
import "./TokenPlatform.css";

export default function TokenPlatform() {
  return (
    <div className="container">
      

    </div>
  )
}
